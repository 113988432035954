<template>
  <div class="relative" :class="{ 'km-select': open }">
    <div
      :style="listStyle"
      :class="{ 'shadow-black shadow-lg': open }"
      class="absolute select-none cursor-pointer bg-white whitespace-nowrap -mt-2 py-2"
    >
      <div
        v-for="(option, index) in options"
        :key="index"
        :value="option.value"
        class="flex px-3"
        :class="{ 'hover:bg-grey': open, hidden: !option.selected && !open }"
        @click="(selectOption(option), (open = !open))"
      >
        <div>{{ option.description }}</div>
        <div class="px-2" :class="{ hidden: open || !option.selected }">
          <KmIcon name="chevron" class="km-rotate-start h-[14px] w-[14px] align-middle" />
        </div>
      </div>
    </div>
  </div>
  <div v-if="open" class="modal-bg km-select-bg" @click.stop="open = false"></div>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue'
import KmIcon from '../general/KmIcon.vue'
import type { KmSelectBoxOption } from '../../interfaces/ComponentInterfaces'

defineProps<{
  options: KmSelectBoxOption[]
}>()

const emit = defineEmits<{
  (e: 'optionUpdate', option: KmSelectBoxOption): void
}>()

const open = ref(false)

function selectOption(option: KmSelectBoxOption) {
  if (open.value) {
    emit('optionUpdate', option)
  }
}

const listStyle = computed(() => {
  return open.value && `top:0rem`
})
</script>
