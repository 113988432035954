import { defineStore } from 'pinia'
import type { Artwork, Product, ProductOptions } from '../interfaces/ProductInterfaces'
import useProductOptions from '../hooks/useProductOptions'
import useWindow from '../hooks/useWindow'
import useProductDetails from '../hooks/useProductDetails'
import { first, get } from 'lodash-es'

interface ProductState {
  artwork: Artwork | undefined
  podMedia: object | undefined
  productOptions: ProductOptions | null
  activeProduct: Product | null
  activeOptions: ProductOptions | null
  framingLeadTime: number
  loading: boolean
  htmlSnippets: unknown | null
  error: Error | null
  viewInRoom: boolean
}

interface GalleryItem {
  type: string
  src: string
  caption?: string
  url?: string
  style?: string
  featured: boolean
}

const Product = useProductDetails()

export const useProductStore = defineStore('product', {
  state: (): ProductState => ({
    artwork: undefined,
    podMedia: undefined,
    productOptions: null,
    activeProduct: null,
    activeOptions: null,
    framingLeadTime: 2,
    loading: false,
    htmlSnippets: null,
    error: null,
    viewInRoom: false
  }),

  getters: {
    isFramed: (state) => (state.activeProduct ? Product.isFramed(state.activeProduct) : false),
    isPod: (state) => (state.activeProduct ? Product.isPod(state.activeProduct) : false),
    isSkuProduct: (state) => (state.activeProduct ? Product.isSkuProduct(state.activeProduct) : false),
    isUnique: (state) =>
      state.activeProduct && state.artwork?.categories ? Product.isUnique(state.artwork.categories) : false,
    isTier1: (state) =>
      state.activeProduct && state.artwork?.retail_tier_id ? Product.isTier1(state.artwork.retail_tier_id) : false,
    isTier3: (state) =>
      state.activeProduct && state.artwork?.retail_tier_id ? Product.isTier3(state.artwork.retail_tier_id) : false,
    productStockLevel(state: ProductState) {
      return get(state.activeProduct, 'stock_level', 0)
    },
    getProductWidth(state: ProductState) {
      return state.activeProduct?.dimensions?.width
    },
    getViewInARoom(state) {
      return state.viewInRoom
    }
  },

  actions: {
    setHtmlSnippets(snippets: unknown) {
      this.htmlSnippets = snippets
      // todo: make these snippets expand into the correct parts (if we need / use more than one)
    },

    async setArtwork(artwork: Artwork, selectedProductId?: string) {
      this.$reset()
      this.artwork = artwork
      this.fetchFramingLeadTime()

      return this.fetchProducts(selectedProductId)
    },

    async setPodMedia(podMedia: object) {
      this.podMedia = podMedia
    },

    async fetchProducts(selectedProductId?: string) {
      if (this.artwork) {
        let selectedProduct
        if (selectedProductId) {
          selectedProduct = this.artwork.products.find((product) => {
            return product.id === selectedProductId
          })
        }

        if (!selectedProduct) {
          if (this.artwork.default_products && this.artwork.default_products.length > 0) {
            selectedProduct = first(this.artwork.default_products)
          } else {
            // console.log('product-store: no selected product or default_products!', selectedProduct)
          }
        }

        // console.log('product-store: selected product', selectedProduct)
        if (selectedProduct) {
          this.loading = true
          try {
            this.fetchOptions(selectedProduct).catch((_error) => {
              // console.log('product-store: error fetching options', error)
              // todo: report / catch errors
            })
          } catch (_error) {
            // console.log('product-store: error', error)
          } finally {
            this.loading = false
          }
        }
      } else {
        // console.log('product-store: no artwork to fetch products for')
      }
    },

    updateProductOptions(productId: string) {
      if (this.artwork) {
        const foundProduct = this.artwork.products.find((x) => x.id == productId)
        if (foundProduct) {
          this.fetchOptions(foundProduct).catch((_error) => {
            // console.log('product-store: error fetching options', error)
            // todo: report / catch errors
          })
        }
      }
    },

    async fetchFramingLeadTime(): Promise<void> {
      const { fetchFramingLeadTime } = useProductOptions()
      this.framingLeadTime = await fetchFramingLeadTime()
    },

    async fetchOptions(selectedProduct: Product): Promise<void> {
      const { fetchForProduct } = useProductOptions()
      const { updateWindowHash } = useWindow()
      const options: ProductOptions = (await fetchForProduct(selectedProduct.id)) as ProductOptions
      this.activeProduct = selectedProduct
      this.activeOptions = options
      updateWindowHash(selectedProduct.id)
    },

    toggleViewInARoom(): void {
      this.viewInRoom = !this.viewInRoom
    },

    setProductGalleryItems(artwork: Artwork, product: Product): GalleryItem[] {
      const galleryItems = []

      artwork.videos.forEach(function (video) {
        galleryItems.push({
          type: 'video',
          src: video.thumbnail.url,
          url: `https://www.youtube.com/watch?v=${video.id}`,
          caption: video.title,
          style: `background:url('${video.thumbnail.url}');background-size:cover;`
        })
      })

      if (artwork.image_urls.length > 0) {
        artwork.image_urls.forEach(function (image) {
          galleryItems.push({ src: image.url, caption: image.caption, type: 'image', featured: image.featured })
        })
      } else {
        product.images.forEach(function (image) {
          galleryItems.push({ src: image, type: 'image' })
        })
      }

      if (product.frame) {
        const frameId = product.frame.id
        if (![472945, 472946, 472690, 473067, 473068].includes(artwork.id)) {
          galleryItems.push({
            src: `https://img.kingandmcgaw.com/i/frames/corner/${frameId}.jpg`,
            type: 'image',
            caption: product.frame.title
          })
        }

        if (![473067, 473068, 473309, 473310].includes(artwork.id)) {
          galleryItems.push({
            type: 'inline-video',
            src: 'https://kmg-2022.cdn.prismic.io/kmg-2022/ZzNZ7K8jQArT0woA_Hanging_pack_4.5.mp4',
            caption: 'Ready to hang',
            mimeType: 'video/mp4',
            style: `background:url('/assets/global/how-to-hang-a-frame.jpg');background-size:cover;`
          })
        }

        if (![472945, 472946, 472690, 473067, 473068].includes(artwork.id)) {
          if (product.mount && product.mount.recessed) {
            galleryItems.push({
              src: `https://img.kingandmcgaw.com/i/frames/recessed/${frameId}.jpg`,
              type: 'image',
              caption: 'Recessed box'
            })
          }
        }
      }

      return galleryItems
    }
  }
})
