<template>
  <div class="flex justify-end items-center">
    <div class="flex mb-2 items-center typeset-8" :title="`Set ${currentIndex}`">
      <button class="swiper-button-prev" @click="prevPage">
        <KmIcon name="angle" class="rotate-180" />
      </button>
      <button class="swiper-button-next" @click="nextPage">
        <KmIcon name="angle" />
      </button>
    </div>
  </div>

  <div class="relative">
    <img :src="currentSet.bgImageMobile" class="block md:hidden" @click="clickedHotspot" />
    <img :src="currentSet.bgImageDesktop" class="hidden md:block w-full" @click="clickedHotspot" />

    <div v-for="item in currentSet.items" :key="item.itemId" :style="hotspotStyle(item)" class="hotspot-container">
      <InspirationHotspot :item="item" @clicked-hotspot="clickedHotspot" />
    </div>
  </div>
  <a :href="addToCartLink()" class="button button-primary button-short mb-2 mt-2">Add all to basket</a>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref, watch } from 'vue'
import InspirationHotspot from './InspirationHotspot.vue'
import type { Hotspot } from './interfaces/ProductInterfaces'
import { debounce } from 'lodash'

const inspirationSets = ref([])
const currentIndex = ref(null)
const windowWidth = ref(window.innerWidth)

onMounted(() => {
  inspirationSets.value = window.inspiration_sets
  currentIndex.value = (parseInt(location.hash.substring(1), 10) || 1) - 1
  window.addEventListener('resize', updateWidth)
})

watch(currentIndex, (newVal) => {
  window.location.hash = `#${newVal + 1}`
})

const currentSet = computed(() => {
  return inspirationSets.value[currentIndex.value] || { bgImageMobile: '', bgImageDesktop: '' }
})

const updateWidth = debounce(() => {
  windowWidth.value = window.innerWidth
}, 100)

function clickedHotspot(itemId: number) {
  hideAllHotSpots()
  currentSet.value.items.forEach((item) => {
    if (itemId == item.itemId) {
      item.visible = true
      item.zIndex = 20
    }
  })
}

function hideAllHotSpots() {
  currentSet.value.items.forEach((item) => {
    item.visible = false
    item.zIndex = 10
    item.contextMenuVisible = false
  })
}

function hotspotStyle(item: Hotspot) {
  if (windowWidth.value < 768) {
    return {
      position: 'absolute',
      left: `${item.coords.mobile.x}%`,
      top: `${item.coords.mobile.y}%`,
      zIndex: item.zIndex
    }
  } else {
    return {
      position: 'absolute',
      left: `${item.coords.desktop.x}%`,
      top: `${item.coords.desktop.y}%`,
      zIndex: item.zIndex
    }
  }
}

function prevPage() {
  hideAllHotSpots()
  if (currentIndex.value > 0) {
    currentIndex.value -= 1
  } else {
    currentIndex.value = inspirationSets.value.length - 1
  }
}

function nextPage() {
  hideAllHotSpots()
  if (currentIndex.value < inspirationSets.value.length - 1) {
    currentIndex.value += 1
  } else {
    currentIndex.value = 0
  }
}

function addToCartLink() {
  return `/gallery/${currentSet.value.id}/add_to_cart`
}
</script>
