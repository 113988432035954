import useHttps from './useHttps'
const { get } = useHttps()

export function getXmasShippingAvailability(artwork_id: number, isFramed: boolean, isPod: boolean) {
  const url = new URL('https://secure.kingandmcgaw.com/api/v2/ships_for_xmas/')
  // const url = new URL('http://localhost:3000/ships_for_xmas')

  const params = new URLSearchParams()
  params.append('print_id', artwork_id.toString())
  params.append('country_id', '1')
  params.append('json', '1')

  if (isFramed) {
    params.append('framed', '1')
  }

  if (isPod) {
    params.append('is_pod', '1')
  }

  url.search = params.toString()
  return get(url.toString()).then((res) => res)
}
