import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex" }
const _hoisted_2 = ["href"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock($setup["MarqueeText"], {
    duration: 150,
    repeat: 5,
    paused: $setup.marqueePaused,
    class: "border-y py-2",
    onMouseover: _cache[0] || (_cache[0] = $event => ($setup.marqueePaused = true)),
    onMouseleave: _cache[1] || (_cache[1] = $event => ($setup.marqueePaused = false))
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.marqueeLinks, (link) => {
          return (_openBlock(), _createElementBlock("div", { key: link }, [
            (link.url)
              ? (_openBlock(), _createElementBlock("a", {
                  key: 0,
                  href: link.url,
                  class: _normalizeClass(["underscore-hover", $setup.itemClass()])
                }, _toDisplayString(link.title), 11, _hoisted_2))
              : (_openBlock(), _createElementBlock("span", {
                  key: 1,
                  class: _normalizeClass($setup.itemClass())
                }, _toDisplayString(link.title), 3))
          ]))
        }), 128))
      ])
    ]),
    _: 1
  }, 8, ["paused"]))
}