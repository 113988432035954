<template>
  <div v-if="artwork" class="col-span-6 lg:col-start-8 lg:col-end-13 mt-4 md:mt-0">
    <p v-if="error">{{ error.message }}</p>

    <div class="flex justify-between">
      <h1 class="flex-grow mb-3">{{ artwork.title }}</h1>
      <div class="ml-2 lg:mt-3">
        <PinterestButton v-if="activeProduct?.id"></PinterestButton>
      </div>
      <div class="ml-2 lg:mt-3">
        <WishListButton v-if="activeProduct?.id" :product-id="activeProduct.id"></WishListButton>
      </div>
    </div>

    <a class="block typeset-4 mb-3 hover:underline" :href="artistHref()" data-test="artist-name">{{ artistName() }}</a>

    <p v-if="productTypeDescription()" class="mb-3">{{ productTypeDescription() }}</p>

    <p class="typeset-8">#{{ artwork.id }}</p>

    <ArtworkRating></ArtworkRating>

    <template v-if="showProductForm() && activeOptions">
      <ProductForm
        :active-options="activeOptions"
        :active-product="activeProduct"
        :artwork-product-list="artwork.products"
        class="mt-12 mb-5"
        @update-options="updateOptions"
        @add-to-cart="addProductToCart"
      />

      <KmMakeEnquiry
        v-if="priceOver(499)"
        :product-id="activeProduct?.id"
        :product-title="artwork.title"
        class="mb-5"
      />
    </template>

    <template v-else>
      <button disabled class="button w-full mb-5">Sold</button>
    </template>

    <button
      v-if="profile.internal"
      ref="defaultProductButton"
      class="button w-full mb-5"
      @click="updateDefaultProduct()"
    >
      Update default product
    </button>

    <div v-if="showDescription && productDescriptionText" class="pt-4 mb-8 text-format">
      <KmReadMore><div v-html="productDescriptionText" /></KmReadMore>
    </div>

    <div v-if="artwork.copyright" class="mb-5">
      {{ artwork.copyright }}
    </div>

    <KmPartnership />

    <KmAccordion v-if="showPanels" :panels="accordionPanels" default-open="2"></KmAccordion>

    <div class="mb-3">Tags</div>
    <KmTags :tags="artwork.tags" :created-at="artwork.created_at"></KmTags>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue'
import { storeToRefs } from 'pinia'
import { useCartStore } from '../../stores/useCartStore'
import { useProductStore } from '../../stores/useProductStore'
import { useSessionStore } from '../../stores/useSessionStore'
import KmAccordion from '../general/KmAccordion.vue'
import KmMakeEnquiry from '../general/KmMakeEnquiry.vue'
import KmPartnership from '../general/KmPartnership.vue'
import KmReadMore from '../general/KmReadMore.vue'
import KmTags from '../general/KmTags.vue'
import ArtworkRating from './ArtworkRating.vue'
import PinterestButton from '../../PinterestButton.vue'
import ProductDimensionsPanel from './ProductDimensionsPanel.vue'
import ProductForm from './ProductForm.vue'
import ProductMaterialsPanel from './ProductMaterialsPanel.vue'
import ProductReturnsPanel from './ProductReturnsPanel.vue'
import ProductShippingPanel from './ProductShippingPanel.vue'
import useProductOptions from '../../hooks/useProductOptions'
import useTracking from '../../hooks/useTracking'
import WishListButton from '../../WishListButton.vue'

import type { CartProduct } from '../../interfaces/CartInterfaces'
const { trackAddItem } = useTracking()

defineProps({
  showDescription: { type: Boolean, default: true, required: false },
  showPanels: { type: Boolean, default: true, required: false }
})

const productStore = useProductStore()
const sessionStore = useSessionStore()

const { updateProductOptions } = productStore
const { addToCart, addCartError, clearCartErrors, toggleMiniCartVisibility } = useCartStore()
const { artwork, error, activeOptions, activeProduct, isSkuProduct } = storeToRefs(productStore)
const { postDefaultProduct } = useProductOptions()
const { profile } = storeToRefs(sessionStore)
const defaultProductButton = ref()

const accordionPanels = [
  { heading: 'Further details', content: ProductMaterialsPanel },
  { heading: 'Dimensions', content: ProductDimensionsPanel },
  { heading: 'Shipping', content: ProductShippingPanel },
  { heading: 'Returns', content: ProductReturnsPanel }
]

const productDescriptionText = computed<string>(() => {
  if (isSkuProduct.value && activeProduct?.value?.description) {
    return activeProduct.value.description
  } else if (artwork?.value?.description) {
    return artwork.value.description
  }

  return ''
})

async function updateDefaultProduct() {
  defaultProductButton.value.innerText = '...'

  await postDefaultProduct(artwork.value, activeProduct.value).then((response) => {
    if (response == true) {
      defaultProductButton.value.innerText = 'Success'
    } else {
      defaultProductButton.value.innerText = 'Failed'
    }
  })
}

function addProductToCart(productId: string) {
  if (activeProduct.value && productId === activeProduct.value.id) {
    const product = activeProduct.value
    const cartProduct: CartProduct = {
      description: product.description,
      frame: product.frame,
      id: product.id,
      metadata: artwork.value?.metadata,
      title: product.title,
      type: product.type
    }

    // Tracking wrapper (GA, Klaviyo etc.)
    trackAddItem(product, artwork.value)

    clearCartErrors()

    addToCart(cartProduct)
      .catch((_error) => {
        addCartError('Error adding item to cart')
      })
      .finally(() => {
        toggleMiniCartVisibility()
        window.scrollTo(0, 0)
      })
  } else {
    console.log('product-details: Tried to add invalid product to cart')
  }
}

function updateOptions(productId: string) {
  updateProductOptions(productId)
}

function priceOver(pounds: number) {
  if (activeProduct.value?.price) {
    return activeProduct.value?.price / 100 > pounds
  } else if (activeOptions.value?.selected.price) {
    return activeOptions.value?.selected.price / 100 > pounds
  }
  return false
}

function artistHref() {
  if (artwork.value.collection) {
    return `/prints/${artwork.value.collection.slug}`
  } else {
    return `/prints/${artwork.value.artist.slug}`
  }
}

function artistName() {
  if (artwork.value.collection) {
    return artwork.value.collection.title
  } else {
    return artwork.value.artist.full_name
  }
}

function showProductForm() {
  return artwork.value.status_id > 100 && artwork.value.status_id !== 111
}

function productTypeDescription() {
  if (artwork.value.edition_size > 0) {
    return `Limited edition of ${artwork.value.edition_size}`
  } else if (artwork.value.product_type !== 'Art Print') {
    return artwork.value.product_type
  }
}
</script>
