import type { ArtworkMetadata, CoreArtwork, CoreProduct, Product } from './ProductInterfaces'

export const SKU_PRODUCT = 'SkuProduct'
export const VOUCHER = 'Voucher'

export interface Cart {
  id: string
  items: CartProduct[]
  created_at: string
  updated_at: string
  num_items: number
}

export interface CartItem {
  id: number
  artwork: CartArtwork
  image_url: string
  image_url_lowres: string
  item_id: string
  item_type: string
  metadata: unknown
  price: number
  quantity: number
  title: string
  valid: boolean
  status_id: number
  errors: unknown
  default_products?: Product[]
}

export interface CartFrame {
  id?: number
  frame_id: number | null
}

export interface CartProduct extends CoreProduct {
  frame?: CartFrame | null
  metadata: ArtworkMetadata | undefined
}

export interface CartArtwork extends CoreArtwork {
  products: CartProduct[]
}
