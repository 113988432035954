<template>
  <div
    class="hotspot"
    :class="{ 'hotspot-close': item.visible }"
    :title="item.itemId.toString()"
    @click="onClickedHotspot"
    @contextmenu.prevent="item.contextMenuVisible = !item.contextMenuVisible"
  ></div>
  <div v-show="item.visible" :class="hotspotDetailsClass()" class="hotspot-details grid gap-4">
    <div class="col-span-2">
      <a :href="item.url" target="_blank"><img :src="item.image" /></a>
    </div>
    <div class="col-span-3">
      <div>
        <a :href="item.artistUrl" class="underscore-hover" target="_blank">{{ item.artistName }}</a>
      </div>
      <div>{{ item.printTitle }}</div>
      <div>{{ item.productType }}</div>
      <div>{{ item.dimensions }}</div>
    </div>
    <div class="col-span-5">
      <a :href="item.url" class="button button-primary w-full whitespace-nowrap" target="_blank"
        >{{ item.price }} - View print</a
      >
    </div>
  </div>
  <div class="pulse"></div>

  <div v-show="item.contextMenuVisible && profile.internal" :class="hotspotDetailsClass()" class="hotspot-details">
    <div class="hidden md:flex gap-2 items-center mb-2">
      x: <input v-model.number="item.coords.desktop.x" type="number" min="0" max="100" maxlen="3" /> y:
      <input v-model.number="item.coords.desktop.y" type="number" min="0" max="100" maxlen="3" />
    </div>
    <div class="flex md:hidden gap-2 items-center mb-2">
      x: <input v-model.number="item.coords.mobile.x" type="number" min="0" max="100" maxlen="3" /> y:
      <input v-model.number="item.coords.mobile.y" type="number" min="0" max="100" maxlen="3" />
    </div>
    <button ref="saveCoordsButton" class="button w-full" @click="clickSaveCoords">Save</button>
  </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue'
import { storeToRefs } from 'pinia'
import { useSessionStore } from './stores/useSessionStore'
import Axios from 'axios'
import type { Hotspot } from './interfaces/ProductInterfaces'

const props = defineProps<{
  item: Hotspot
}>()
const emit = defineEmits(['clickedHotspot'])
const item = ref(props.item)
const { profile } = storeToRefs(useSessionStore())
const { getOwnerId } = useSessionStore()
const saveCoordsButton = ref(null)

function clickSaveCoords() {
  const url = `/api/lists/${item.value.listId}/items/${item.value.itemId}`
  getOwnerId().then((ownerId) => {
    const body = {
      owner_id: ownerId,
      id: item.value.itemId,
      list_id: item.value.listId,
      metadata: {
        coords: {
          desktop: item.value.coords.desktop,
          mobile: item.value.coords.mobile
        }
      }
    }

    return Axios.patch(url, body).then(function (response) {
      if (response.status === 200) {
        saveCoordsButton.value.style = 'background-color: green; border-color: green;'
      } else {
        saveCoordsButton.value.style = 'background-color: red; border-color: red;'
      }
    })
  })
}

function hotspotDetailsClass() {
  const type: string = window.innerWidth < 768 ? 'mobile' : 'desktop'

  const x: number = props.item.coords[type].x
  if (x < 34) {
    return 'hotspot-details-right'
  } else if (x > 65) {
    return 'hotspot-details-left'
  } else {
    return 'hotspot-details-middle'
  }
}

function onClickedHotspot() {
  if (item.value.visible) {
    emit('clickedHotspot')
  } else {
    emit('clickedHotspot', item.value.itemId)
  }
}
</script>
