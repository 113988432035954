<template>
  <div v-if="comp.slug">
    <div class="grid grid-cols-5 gap-x-6 border-t py-4 divide-x items-center">
      <div class="col-span-3">
        <div class="typeset-8">
          {{ comp.text }} <a :href="comp.url">{{ comp.title.toUpperCase() }}</a>
        </div>
      </div>
      <div class="col-span-2 pl-2">
        <a :href="comp.url" class="collection-icon mx-auto max-h-10" :class="comp.slug">&nbsp;</a>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { intersection, startCase } from 'lodash-es'
import { storeToRefs } from 'pinia'
import { useProductStore } from '../../stores/useProductStore'

// NB: if a new collection slug is added to this list, it must also be added to stylesheets/modules/_collection_icons.scss
const slugs = [
  'archigram',
  'art-inspired-by-music',
  'chatsworth-house',
  'dacs',
  'do-the-green-thing',
  'flower-fairies',
  'gagosian',
  'galerie-mourlot',
  'hepworth-wakefield',
  'hollywood-photo-archive',
  'illustrated-london-news',
  'imperial-war-museums',
  'james-bond-collection',
  'ladybird-books',
  'london-metropolitan-archives',
  'london-transport-museum',
  'mirrorpix',
  'national-galleries-of-scotland',
  'national-gallery',
  'national-portrait-gallery',
  'national-railway-museum',
  'p-o-heritage',
  'penguin-books',
  'petersburg-press',
  'projekt-26',
  'reign-hail',
  'royal-academy-of-arts',
  'royal-horticultural-society',
  'soane-museum',
  'stilltime',
  'tate',
  'tatler',
  'team-gb',
  'the-courtauld-gallery',
  'the-fitzwilliam-museum',
  'the-highgrove-florilegium',
  'the-lowry',
  'the-national-archives',
  'the-snowman',
  'v-and-a',
  'veebee',
  'vogue',
  'william-morris-gallery'
]

const { artwork } = storeToRefs(useProductStore())

const comp = computed(() => {
  const cats = artwork && artwork.value && artwork.value.categories ? artwork.value.categories : []
  const slugSection = intersection(cats.flat(), slugs)
  const slug = slugSection && slugSection.length > 0 ? slugSection[0] : ''
  const title = startCase(slug).replace(' And ', '&')
  const image = `/assets/categories/${slug}.png`
  const url = `/prints/${slug}`
  const text = slug == `team-gb` ? `In collaboration with` : `In partnership with`

  return {
    image,
    slug,
    text,
    title,
    url
  }
})
</script>
