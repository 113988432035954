<template>
  <marquee-text
    :duration="150"
    :repeat="5"
    :paused="marqueePaused"
    class="border-y py-2"
    @mouseover="marqueePaused = true"
    @mouseleave="marqueePaused = false"
  >
    <div class="flex">
      <div v-for="link in marqueeLinks" :key="link">
        <a v-if="link.url" :href="link.url" class="underscore-hover" :class="itemClass()">
          {{ link.title }}
        </a>
        <span v-else :class="itemClass()">{{ link.title }}</span>
      </div>
    </div>
  </marquee-text>
</template>

<script lang="ts" setup>
import MarqueeText from 'vue-marquee-text-component'
import { onMounted, ref } from 'vue'
const marqueePaused = ref(false)
const marqueeLinks = ref([])

const props = defineProps<{
  links: string
}>()

onMounted(() => {
  marqueeLinks.value = JSON.parse(props.links)
})

function itemClass() {
  return 'ml-8 md:ml-12 lg:ml-24'
}
</script>
