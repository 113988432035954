import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "ml-8 list-disc !mt-0" }
const _hoisted_2 = {
  key: 0,
  class: "text-green"
}
const _hoisted_3 = { key: 1 }
const _hoisted_4 = {
  key: 2,
  class: "text-red"
}
const _hoisted_5 = {
  key: 3,
  class: "hidden"
}
const _hoisted_6 = { key: 4 }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("ul", _hoisted_1, [
      ($setup.shipsInTimeForXmas == true)
        ? (_openBlock(), _createElementBlock("li", _hoisted_2, _cache[0] || (_cache[0] = [
            _createElementVNode("strong", null, "Available for delivery before Christmas – UK mainland", -1)
          ])))
        : _createCommentVNode("", true),
      ($setup.shipsInTimeForXmas == true)
        ? (_openBlock(), _createElementBlock("li", _hoisted_3, " Please check estimated delivery dates for Christmas shipping during checkout "))
        : _createCommentVNode("", true),
      ($setup.shipsInTimeForXmas == false)
        ? (_openBlock(), _createElementBlock("li", _hoisted_4, _cache[1] || (_cache[1] = [
            _createElementVNode("strong", null, "Not available for delivery before Christmas – UK mainland", -1)
          ])))
        : _createCommentVNode("", true),
      ($setup.leadTime == 1)
        ? (_openBlock(), _createElementBlock("li", _hoisted_5, "Ships in 24 hours"))
        : (_openBlock(), _createElementBlock("li", _hoisted_6, "Ships in " + _toDisplayString($setup.leadTime) + " working days or less. Check your estimated delivery date during checkout.", 1)),
      _cache[2] || (_cache[2] = _createElementVNode("li", null, "Framed orders £8.95 for standard UK shipping", -1)),
      _cache[3] || (_cache[3] = _createElementVNode("li", null, "Unframed orders £3.95 for standard UK shipping", -1)),
      _cache[4] || (_cache[4] = _createElementVNode("li", null, "Express delivery available for unframed items", -1)),
      _cache[5] || (_cache[5] = _createElementVNode("li", null, "International orders are priced within the checkout", -1)),
      _cache[6] || (_cache[6] = _createElementVNode("li", null, [
        _createTextVNode(" All of our products are shipped from the UK. "),
        _createElementVNode("a", {
          href: "/help/shipping",
          "data-fancybox": "",
          "data-type": "ajax"
        }, "More about shipping")
      ], -1))
    ])
  ]))
}