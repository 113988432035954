<template>
  <a class="col-span-3" :href="productUrl()">
    <img :src="item.image_url_lowres" />
  </a>

  <div class="col-span-6 flex flex-col">
    <a v-if="artist" :href="`/prints/${artist.slug}`">{{ artist.full_name }}</a>

    <a :href="productUrl()">
      <div v-html="itemTitle()"></div>
    </a>

    <div class="mt-4">
      {{ framedText }} £{{ displayPrice(item.price) }}
      <span v-if="item.quantity > 1">× {{ item.quantity }}</span>
    </div>
  </div>

  <div class="col-span-3 typeset-8">
    <div class="text-right cursor-pointer" data-test="delete-button" @click="$emit('deleteItem', item.id)">Remove</div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { SKU_PRODUCT, VOUCHER } from '../../interfaces/CartInterfaces'
import usePrices from '../../hooks/usePrices'
import type { CartItem } from '../../interfaces/CartInterfaces'

const props = defineProps<{
  item: CartItem
}>()
defineEmits<{
  (e: 'deleteItem', itemId: string): void
}>()

const { displayPrice } = usePrices()

const productId = computed(() => {
  return props.item.item_id
})

const artwork = computed(() => {
  return props.item.artwork
})

const artist = computed(() => {
  return artwork.value?.artist
})

const framedText = computed(() => {
  let text = ''
  if (props.item.item_type !== SKU_PRODUCT && props.item.item_type !== VOUCHER) {
    const product = artwork.value.products.find((item) => item.id === productId.value)
    text = product?.frame ? 'Framed,' : 'Unframed,'
  }
  return text
})

const productUrl = () => {
  if (props.item.item_type === SKU_PRODUCT) {
    return `/product/${productId.value}`
  } else if (props.item.item_type === VOUCHER) {
    return '/gift-vouchers'
  } else {
    return `/prints/${artwork.value.id}#${productId.value}`
  }
}

function itemTitle() {
  if (props.item.item_type === VOUCHER) {
    if (props.item.metadata.type === 'postal') {
      return `${props.item.title}<br><span class="typeset-8">sent by post</span>`
    } else {
      return `${props.item.title}<br><span class="typeset-8">sent by email</span>`
    }
  } else {
    return props.item.title
  }
}
</script>
